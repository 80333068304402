import { useState } from "react";
import $ from "jquery";

import { Outlet, Link } from "react-router-dom";

export default function Contact() {
  const [uname, setUname] = useState("");
  const [passwd, setPasswd] = useState("");
  
  const [result, setResult] = useState("");

  const handleUnameChange = (e) => {
    setUname(e.target.value);
  };
  const handlePasswdChange = (e) => {
    setPasswd(e.target.value);
  };

 
  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };

  return (
    <>
      <div style={{ padding: "50px 300px" }}>
        <div className=" contact-form">
          <h4 className="white-w3ls">
            Contact <span>Form</span>
          </h4>
          <div className="App">
            <form
              action="http://localhost/sanika/demo/signin.php"
              method="post"
              onSubmit={(event) => handleSubmit(event)}
            >
              <div className="styled-input agile-styled-input-top">
                <input
                  onChange={(event) => handleUnameChange(event)}
                  type="text"
                  className
                  id="uname"
                  name="uname"
                  value={uname}
                  placeholder="Enter your first name"
                  style={{
                    width: "100%",
                    padding: "10px",
                    margin: "6px 0",
                  }}
                />
                 <input
                  onChange={(event) => handlePasswdChange(event)}
                  type="password"
                  className
                  id="passwd"
                  name="passwd"
                  value={passwd}
                  placeholder="Enter your password"
                  style={{
                    width: "100%",
                    padding: "10px",
                    margin: "6px 0",
                  }}
                />
                <span />
              </div>
              
              <input type="submit" name="submit" defaultValue="SEND" />
              <br />
            </form>
            <h1>{result}</h1>
          </div>
        </div>
      </div>
    </>
  );
}
