import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function About() {
  return (
    <>
      <Header />
      <div
        className="breadcrumbs d-flex align-items-center"
        style={{ backgroundImage: 'url("assets/img/header.jpg")' }}
      >
        <div className="container position-relative d-flex flex-column align-items-center">
          <h2>About Us</h2>
          <ol>
            <li>
              <Link to="/Home">Home</Link>
            </li>
            <li>About Us</li>
          </ol>
        </div>
      </div>
      {/* End Breadcrumbs */}
      <section id="why-us" className="why-us">
          <div className="container" data-aos="fade-up">
            <div className="section-header">
              <h2>Who We Are</h2>
            </div>
            <div className="row g-0" data-aos="fade-up" data-aos-delay={200}>
              <div
                className="col-xl-5 img-bg"
                style={{ backgroundImage: 'url("assets/img/about.jpg")' }}
              />
              <div className="col-xl-7 slides  position-relative">
                <div className="slides-1 swiper">
                  <div className="swiper-wrapper">
                    <div className="swiper-slide">
                      <div className="item">
                        <h3 className="mb-3">We are {companyname}</h3>

                        <p>
                          {companyname} Services was established by a seasoned
                          professional with extensive experience in the
                          logistics industry. Recognizing the need for expertise
                          and knowledge in freight forwarding, our company was
                          formed to provide exceptional services to the
                          export/import community. Through our commitment to
                          excellence, Prime Logistics quickly gained a
                          reputation as one of the most reliable freight
                          forwarding agents in the industry.
                          <br />
                          <br />
                          Located in Mumbai, the commercial and financial hub of
                          India, our corporate office serves as a strategic base
                          for serving clients globally. With a track record of
                          handling a wide range of commodities in large volumes,
                          odd dimensions, oversized cargo, and various
                          industries, we have developed specialized expertise to
                          meet diverse logistical challenges.
                          <br />
                          <br />
                          Transparency is a core value at {companyname}. We
                          believe in maintaining open and honest communication
                          with our clients, ensuring clarity in every aspect of
                          our operations. By upholding high service standards
                          and implementing cost-effective structures, we have
                          been successful in building long-term relationships
                          with our loyal customers.
                          <br />
                          <br />
                          
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-pagination" />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End Why Choose Us Section */}

        <section id="recent-posts" className="recent-posts">
          <div className="container" data-aos="fade-up">
            <div className="section-header">
              <h2>Services We Provide..</h2>
            </div>
            <div className="row gy-5">
              <div
                className="col-xl-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay={100}>
                <div className="post-box">
                  <div className="post-img">
                    <img
                      src="assets/img/sea.png"
                      className="img-fluid"
                      alt=""
                      style={{ height: "240px", width:"100%"  }}
                    />
                  </div>
                 
                  <h3 className="post-title">
                   Sea Freight
                  </h3>
                  <p>
                   content
                  </p>
                  <Link
                    to="/Sea"
                    className="readmore stretched-link"
                  >
                    <span>Read More</span>
                    <i className="bi bi-arrow-right" />
                  </Link>
                </div>
              </div>
              <div
                className="col-xl-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay={100}>
                <div className="post-box">
                  <div className="post-img">
                    <img
                      src="assets/img/air.png"
                      className="img-fluid"
                      alt=""
                      style={{ height: "240px", width:"100%"  }}
                    />
                  </div>
                 
                  <h3 className="post-title">
                   Air Freight
                  </h3>
                  <p>
                   content
                  </p>
                  <Link
                    to="/Air"
                    className="readmore stretched-link"
                  >
                    <span>Read More</span>
                    <i className="bi bi-arrow-right" />
                  </Link>
                </div>
              </div>
              <div
                className="col-xl-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay={100}>
                <div className="post-box">
                  <div className="post-img">
                    <img
                      src="assets/img/road.webp"
                      className="img-fluid"
                      alt=""
                      style={{ height: "240px", width:"100%" }}
                    />
                  </div>
                 
                  <h3 className="post-title">
                   Road Freight
                  </h3>
                  <p>
                   content
                  </p>
                  <Link
                    to="/Road"
                    className="readmore stretched-link"
                  >
                    <span>Read More</span>
                    <i className="bi bi-arrow-right" />
                  </Link>
                </div>
              </div>
              <div
                className="col-xl-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay={100}>
                <div className="post-box">
                  <div className="post-img">
                    <img
                      src="assets/img/warehousing.webp"
                      className="img-fluid"
                      alt=""
                      style={{ height: "240px", width:"100%"  }}
                    />
                  </div>
                 
                  <h3 className="post-title">
                   Warehousing
                  </h3>
                  <p>
                   content
                  </p>
                  <Link
                    to="/Warehousing"
                    className="readmore stretched-link"
                  >
                    <span>Read More</span>
                    <i className="bi bi-arrow-right" />
                  </Link>
                </div>
              </div>
              <div
                className="col-xl-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay={100}>
                <div className="post-box">
                  <div className="post-img">
                    <img
                      src="assets/img/consolidation.webp"
                      className="img-fluid"
                      alt=""
                      style={{ height: "240px", width:"100%"  }}
                    />
                  </div>
                 
                  <h3 className="post-title">
                   Freight Consolidation
                  </h3>
                  <p>
                   content
                  </p>
                  <Link
                    to="/Consolidation"
                    className="readmore stretched-link"
                  >
                    <span>Read More</span>
                    <i className="bi bi-arrow-right" />
                  </Link>
                </div>
              </div>
              <div
                className="col-xl-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay={100}>
                <div className="post-box">
                  <div className="post-img">
                    <img
                      src="assets/img/dangerous.jpg"
                      className="img-fluid"
                      alt=""
                      style={{ height: "240px", width:"100%" }}
                    />
                  </div>
                 
                  <h3 className="post-title">
                   Dangerous Cargo
                  </h3>
                  <p>
                   content
                  </p>
                  <Link
                    to="/Dangerous"
                    className="readmore stretched-link"
                  >
                    <span>Read More</span>
                    <i className="bi bi-arrow-right" />
                  </Link>
                </div>
              </div>
              
            </div>
            
          </div>
        </section>
      <Footer />
    </>
  );
}
