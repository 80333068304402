import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Air() {
  return (
    <>
      <Header />
      <div
        className="breadcrumbs d-flex align-items-center"
        style={{ backgroundImage: 'url("assets/img/header.jpg")' }}
      >
        <div className="container position-relative d-flex flex-column align-items-center">
          <h2>Road Freight</h2>
          <ol>
            <li>
              <Link to="/Home">Home</Link>
            </li>
            <li>Road Freight</li>
          </ol>
        </div>
      </div>
      {/* End Breadcrumbs */}

      <section id="blog" className="blog">
        <div className="container" data-aos="fade-up">
          <div className="row g-5">
            <div className="col-lg-8" data-aos="fade-up" data-aos-delay={200}>
              <article className="blog-details">
                <div className="post-img">
                  <img style={{width:"100%"}} src="assets/img/road.webp" alt="" className="img-fluid" />
                </div>
                <h2 className="title">Road Freight</h2>
                <div className="meta-top"></div>
                {/* End meta top */}
                <div className="content">
                  <p>
                    At TCB Group{companyname}, we take pride in providing highly
                    effective and efficient road freight solutions that cater to
                    the diverse needs of our clients. Whether you require
                    standalone road freight services or wish to incorporate them
                    as part of a comprehensive freight forwarding plan, we have
                    you covered.
                    <br />
                    <br />
                    Our road freight services are designed to ensure smooth and
                    seamless transportation of goods, enabling businesses to
                    move their products with ease and confidence. With a strong
                    network of reliable carriers and experienced logistics
                    professionals, we offer flexible and tailored solutions to
                    meet the unique requirements of each shipment.
                    <br />
                    <br />
                    As a leading freight forwarding company, we understand the
                    importance of timely and reliable deliveries. Our road
                    freight services offer a cost-effective and practical option
                    for transporting goods to various destinations, both locally
                    and across borders. From small parcels to oversized cargo,
                    we have the capability to handle diverse loads efficiently.
                    <br />
                    <br />
                    At {companyname}, we prioritize customer satisfaction and
                    strive to exceed expectations at every step of the
                    transportation process. Our team of experts ensures that all
                    road freight operations are carried out with the highest
                    level of precision and attention to detail, minimizing
                    delays and optimizing delivery times.
                    <br />
                    <br />
                    Whether you need a one-time shipment or require regular road
                    freight services, our experienced team is ready to assist
                    you. We take pride in our commitment to delivering
                    exceptional service and maintaining long-term partnerships
                    with our clients. With {companyname}, you can trust that
                    your road freight needs are in capable hands.
                    <br />
                    <br />
                  </p>
                </div>
                {/* End post content */}
              </article>
              {/* End blog post */}
            </div>
            <div className="col-lg-4" data-aos="fade-up" data-aos-delay={400}>
              <div className="sidebar ps-lg-4">
                <div className="sidebar-item recent-posts">
                  <h3 className="sidebar-title">Our Services</h3>
                  <div className="mt-3">
                    <div className="post-item mt-3">
                      <img
                        src="assets/img/sea.png"
                        alt=""
                        className="flex-shrink-0"
                      />
                      <div>
                        <h3>
                          <Link to="/Sea">Sea Freight</Link>
                        </h3>
                      </div>
                    </div>
                    {/* End recent post item*/}
                    <div className="post-item mt-3">
                      <img
                        src="assets/img/air.png"
                        alt=""
                        className="flex-shrink-0"
                      />
                      <div>
                        <h3>
                          <Link to="/Air">Air Freight</Link>
                        </h3>
                      </div>
                    </div>
                    {/* End recent post item*/}
                    <div className="post-item mt-3">
                      <img
                        src="assets/img/road.webp"
                        alt=""
                        className="flex-shrink-0"
                      />
                      <div>
                        <h3>
                          <Link to="/Road">Road Freight</Link>
                        </h3>
                      </div>
                    </div>
                    {/* End recent post item*/}
                    <div className="post-item mt-3">
                      <img
                        src="assets/img/warehousing.webp"
                        alt=""
                        className="flex-shrink-0"
                      />
                      <div>
                        <h3>
                          <Link to="/Warehousing">Warehousing</Link>
                        </h3>
                      </div>
                    </div>
                    {/* End recent post item*/}
                    <div className="post-item mt-3">
                      <img
                        src="assets/img/consolidation.webp"
                        alt=""
                        className="flex-shrink-0"
                      />
                      <div>
                        <h3>
                          <Link to="/Consolidation">Freight Consolidation</Link>
                        </h3>
                      </div>
                    </div>
                    {/* End recent post item*/}
                    <div className="post-item mt-3">
                      <img
                        src="assets/img/dangerous.jpg"
                        alt=""
                        className="flex-shrink-0"
                      />
                      <div>
                        <h3>
                          <Link to="/Dangerous">Dangerous Cargo</Link>
                        </h3>
                      </div>
                    </div>
                    {/* End recent post item*/}
                  </div>
                </div>
                {/* End sidebar recent posts*/}
              </div>
              {/* End Blog Sidebar */}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
