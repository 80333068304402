import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Footer = () => {
  return (
    <>
      <footer id="footer" className="footer">
        <div className="footer-content">
          <div className="container">
            <div className="row gy-4">
              <div className="col-lg-5 col-md-12 footer-info">
                <Link to="/Home" className=" d-flex align-items-center">
                  {/* Uncomment the line below if you also wish to use an image logo */}
                  {/* <img src="assets/img/logo.png" alt=""> */}
                  <img
                    src="assets/img/logo.png"
                    style={{ height: "120px" }}
                  ></img>
                </Link>
                <p>
                  {" "}
                  {companyname} Services was established by a seasoned
                  professional with extensive experience in the logistics
                  industry. Recognizing the need for expertise and knowledge in
                  freight forwarding, our company was formed to provide
                  exceptional services to the export/import community. Through
                  our commitment to excellence, Prime Logistics quickly gained a
                  reputation as one of the most reliable freight forwarding
                  agents in the industry.
                </p>
                <div className=" d-flex  mt-3">
                <Link
                            className="cta-btn"
                            to="/About"
                            style={{
                              background: "#009846",
                              borderRadius: "50px",
                              padding: "10px 30px",
                              margin: "10px,30px",
                              color: "black",
                            }}
                          >
                            Learn More
                          </Link>
                </div>
              </div>
              <div className="col-lg-2 col-6 footer-links">
                <h4>Useful Links</h4>
                <ul>
                  <li>
                    <i className="bi bi-dash" /> <Link to="/Home">Home</Link>
                  </li>
                  <li>
                    <i className="bi bi-dash" />{" "}
                    <Link to="/About">About Us</Link>
                  </li>
                  <li>
                    <i className="bi bi-dash" />{" "}
                    <Link to="/Services">Services</Link>
                  </li>
                  <li>
                    <i className="bi bi-dash" />{" "}
                    <Link to="/Privacy">Privacy Policy</Link>
                  </li>
                  <li>
                    <i className="bi bi-dash" />{" "}
                    <Link to="/Terms">Terms & Conditions</Link>
                  </li>
                  <li>
                    <i className="bi bi-dash" />{" "}
                    <Link to="/Contact">Contact Us</Link>
                  </li>
                  <li>
                    <i className="bi bi-dash" />{" "}
                    <Link to="/Getquote">Get Quote</Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-2 col-6 footer-links">
                <h4>Our Services</h4>
                <ul>
                  <li>
                    <i className="bi bi-dash" />{" "}
                    <Link to="/Sea">Sea Freight</Link>
                  </li>
                  <li>
                    <i className="bi bi-dash" />{" "}
                    <Link to="/Air">Air Freight</Link>
                  </li>
                  <li>
                    <i className="bi bi-dash" />{" "}
                    <Link to="/Road">Road Freight</Link>
                  </li>
                  <li>
                    <i className="bi bi-dash" />{" "}
                    <Link to="/Warehousing">Warehousing</Link>
                  </li>
                  <li>
                    <i className="bi bi-dash" />{" "}
                    <Link to="/Consolidation">Freight Consolidation</Link>
                  </li>
                  <li>
                    <i className="bi bi-dash" />{" "}
                    <Link to="/Dangerous">Dangerous Cargo</Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-12 footer-contact text-center text-md-start">
                <h4>Contact Us</h4>
                <p>
                  <strong>Phone:</strong> {companyaddress}
                  <br />
                  <strong>Phone:</strong> {companynumber}
                  <br />
                  <strong>Email:</strong> {companyemail}
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-legal">
          <div className="container">
            <div className="copyright">
              © Copyright{" "}
              <strong>
               <Link to="/Home"><span>{companyname}</span></Link> 
              </strong>
              . All Rights Reserved
            </div>
           
          </div>
        </div>
      </footer>
      {/* End Footer */}
      {/* End Footer */}
      <Link
        to="#"
        className="scroll-top d-flex align-items-center justify-content-center"
      >
        <i className="bi bi-arrow-up-short" />
      </Link>

      <Outlet />
    </>
  );
};

export default Footer;
