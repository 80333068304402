import { useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Contact() {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [result, setResult] = useState("");

  const handleFnameChange = (e) => {
    setFname(e.target.value);
  };
  const handlelnameChange = (e) => {
    setLname(e.target.value);
  };
  const handleemailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlesubjectChange = (e) => {
    setSubject(e.target.value);
  };
  const handlemessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      <Header />

      {/* Page Header Start */}

      <div
        className="breadcrumbs d-flex align-items-center"
        style={{ backgroundImage: 'url("assets/img/header.jpg")' }}
      >
        <div className="container position-relative d-flex flex-column align-items-center">
          <h2>Contact</h2>
          <ol>
            <li>
              <Link to="/Home">Home</Link>
            </li>
            <li>Contact</li>
          </ol>
        </div>
      </div>
      {/* End Breadcrumbs */}
      {/* ======= Contact Section ======= */}
      <section id="contact" className="contact">
        <div className="container position-relative" data-aos="fade-up">
          <div className="row gy-4 d-flex justify-content-end">
            <div className="section-header">
              <h2>Contact Us</h2>
              <h4>
                We're here to help! Drop us a message, and we'll be happy to
                assist you with your requirements.
              </h4>
            </div>
            <div className="col-lg-5" data-aos="fade-up" data-aos-delay={100}>
              <div className="info-item d-flex">
                <i className="bi bi-geo-alt flex-shrink-0" />
                <div>
                  <h4>Location:</h4>
                  <p>{companyaddress}</p>
                </div>
              </div>
              {/* End Info Item */}
              <div className="info-item d-flex">
                <i className="bi bi-envelope flex-shrink-0" />
                <div>
                  <h4>Email:</h4>
                  <p>{companyemail}</p>
                </div>
              </div>
              {/* End Info Item */}
              <div className="info-item d-flex">
                <i className="bi bi-phone flex-shrink-0" />
                <div>
                  <h4>Call:</h4>
                  <p>{companynumber}</p>
                </div>
              </div>
              {/* End Info Item */}
            </div>
            <div className="col-lg-6" data-aos="fade-up" data-aos-delay={250}>
              <form
                action="/php/thankyou-contact.php "
                method="post"
                onSubmit={(event) => handleSubmit(event)}
              >
                <div className="row g-3">
                  <div className="col-md-6">
                    <div className="form-floating">
                      <input
                        onChange={(event) => handleFnameChange(event)}
                        type="text"
                        className
                        id="fname"
                        name="fname"
                        value={fname}
                        placeholder="Enter your first name"
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                      />
                      <span />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-floating">
                      <input
                        onChange={(event) => handlelnameChange(event)}
                        type="text"
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                        id="lname"
                        name="lname"
                        value={lname}
                        placeholder="Enter your last name"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating">
                      <input
                        onChange={(event) => handleemailChange(event)}
                        type="email"
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                        id="email"
                        name="email"
                        value={email}
                        placeholder="Enter your Email"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating">
                      <input
                        onChange={(event) => handlesubjectChange(event)}
                        type="text"
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                        id="subject"
                        name="subject"
                        value={subject}
                        placeholder="Enter subject"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating">
                      <textarea
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                        id="message"
                        name="message"
                        rows={1}
                        placeholder="Enter Message"
                        defaultValue={""}
                        value={message}
                        onChange={(event) => handlemessageChange(event)}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <button
                      name="submit"
                      className="cta-btn"
                      type="submit"
                      style={{
                        background: "#009846",
                        borderRadius: "50px",
                        padding: "10px 30px",
                        margin: "10px,30px",
                      }}
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
              <h1>{result}</h1>
            </div>
            {/* End Contact Form */}
          </div>{" "}
          <div
            className="container-xxl pt-5 px-0 wow fadeIn"
            data-wow-delay="0.1s"
          >
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d60275.69726339717!2d72.93440664061507!3d19.228758393632077!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b92abc14627f%3A0x67321c4ebf19795e!2sLake%20City%20Mall!5e0!3m2!1sen!2sin!4v1690278848789!5m2!1sen!2sin"
              width="100%"
              height="450"
             
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
