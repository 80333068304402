import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Air() {
  return (
    <>
      <Header />
      <div
        className="breadcrumbs d-flex align-items-center"
        style={{ backgroundImage: 'url("assets/img/header.jpg")' }}
      >
        <div className="container position-relative d-flex flex-column align-items-center">
          <h2>Dangerous Cargo</h2>
          <ol>
            <li>
              <Link to="/Home">Home</Link>
            </li>
            <li>Dangerous Cargo</li>
          </ol>
        </div>
      </div>
      {/* End Breadcrumbs */}

      <section id="blog" className="blog">
        <div className="container" data-aos="fade-up">
          <div className="row g-5">
            <div className="col-lg-8" data-aos="fade-up" data-aos-delay={200}>
              <article className="blog-details">
                <div className="post-img">
                  <img style={{width:"100%"}}
                    src="assets/img/dangerous.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <h2 className="title">Dangerous Cargo</h2>
                <div className="meta-top"></div>
                {/* End meta top */}
                <div className="content">
                  <p>
                    Shipping dangerous goods is undoubtedly a challenging and
                    intricate task that demands utmost caution and expertise. At{" "}
                    {companyname}, we understand that dangerous or hazardous
                    goods encompass items that can potentially pose risks to
                    life, property, or the environment. With our specialized
                    services, we ensure that these risks are effectively
                    eliminated, providing a safe and compliant solution for
                    transporting such goods.
                    <br />
                    <br />
                    Throughout the entire process of handling hazardous goods,
                    from the point of origin to transit and final destination,
                    we prioritize safety and adherence to shipping regulations.
                    Our team of experts is well-versed in the intricacies of
                    handling dangerous goods, and we strictly adhere to all
                    necessary protocols and safety measures to mitigate any
                    potential risks.
                    <br />
                    <br />
                    When it comes to shipping hazardous goods, each stage
                    requires expert care and attention. Our experienced
                    professionals meticulously plan and execute every step of
                    the transportation process, ensuring that the goods are
                    securely packaged and transported with the highest level of
                    care.
                    <br />
                    <br />
                    At the point of origin, our experts work closely with
                    shippers to ensure proper classification, labeling, and
                    documentation of hazardous goods in compliance with
                    international shipping regulations. This attention to detail
                    is crucial for the smooth transit and delivery of such
                    goods.
                    <br />
                    <br />
                    During transit, our dedicated team ensures that hazardous
                    goods are transported using the most suitable modes and
                    routes, keeping in mind the specific requirements and
                    characteristics of the cargo. We employ specialized
                    equipment and vehicles designed to handle dangerous goods
                    safely and efficiently.
                    <br />
                    <br />
                    At the final destination point, we continue to prioritize
                    safety and compliance by coordinating with local authorities
                    and handling all necessary customs and clearance procedures
                    to ensure smooth delivery.
                    <br />
                    <br />
                    With {companyname}'s hazardous goods shipping services, our
                    clients can have peace of mind knowing that their goods are
                    in capable hands. We understand the importance of safety,
                    reliability, and efficiency when dealing with dangerous
                    goods, and our commitment to excellence ensures that every
                    step of the process is executed flawlessly.
                    <br />
                    <br />
                    In conclusion, our hazardous goods shipping services go
                    beyond just transportation; they encompass a comprehensive
                    approach to handling these goods with the utmost care,
                    ensuring the safety of all involved and the protection of
                    the environment. With {companyname} as their partner,
                    clients can rest assured that their hazardous goods will be
                    delivered securely and in full compliance with all
                    applicable regulations.
                    <br />
                    <br />
                  </p>
                </div>
                {/* End post content */}
              </article>
              {/* End blog post */}
            </div>
            <div className="col-lg-4" data-aos="fade-up" data-aos-delay={400}>
              <div className="sidebar ps-lg-4">
                <div className="sidebar-item recent-posts">
                  <h3 className="sidebar-title">Our Services</h3>
                  <div className="mt-3">
                    <div className="post-item mt-3">
                      <img
                        src="assets/img/sea.png"
                        alt=""
                        className="flex-shrink-0"
                      />
                      <div>
                        <h3>
                          <Link to="/Sea">Sea Freight</Link>
                        </h3>
                      </div>
                    </div>
                    {/* End recent post item*/}
                    <div className="post-item mt-3">
                      <img
                        src="assets/img/air.png"
                        alt=""
                        className="flex-shrink-0"
                      />
                      <div>
                        <h3>
                          <Link to="/Air">Air Freight</Link>
                        </h3>
                      </div>
                    </div>
                    {/* End recent post item*/}
                    <div className="post-item mt-3">
                      <img
                        src="assets/img/road.webp"
                        alt=""
                        className="flex-shrink-0"
                      />
                      <div>
                        <h3>
                          <Link to="/Road">Road Freight</Link>
                        </h3>
                      </div>
                    </div>
                    {/* End recent post item*/}
                    <div className="post-item mt-3">
                      <img
                        src="assets/img/warehousing.webp"
                        alt=""
                        className="flex-shrink-0"
                      />
                      <div>
                        <h3>
                          <Link to="/Warehousing">Warehousing</Link>
                        </h3>
                      </div>
                    </div>
                    {/* End recent post item*/}
                    <div className="post-item mt-3">
                      <img
                        src="assets/img/consolidation.webp"
                        alt=""
                        className="flex-shrink-0"
                      />
                      <div>
                        <h3>
                          <Link to="/Consolidation">Freight Consolidation</Link>
                        </h3>
                      </div>
                    </div>
                    {/* End recent post item*/}
                    <div className="post-item mt-3">
                      <img
                        src="assets/img/dangerous.jpg"
                        alt=""
                        className="flex-shrink-0"
                      />
                      <div>
                        <h3>
                          <Link to="/Dangerous">Dangerous Cargo</Link>
                        </h3>
                      </div>
                    </div>
                    {/* End recent post item*/}
                  </div>
                </div>
                {/* End sidebar recent posts*/}
              </div>
              {/* End Blog Sidebar */}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
