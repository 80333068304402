import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Air() {
  return (
    <>
      <Header />
      <div
        className="breadcrumbs d-flex align-items-center"
        style={{ backgroundImage: 'url("assets/img/header.jpg")' }}
      >
        <div className="container position-relative d-flex flex-column align-items-center">
          <h2>Air Freight</h2>
          <ol>
            <li>
              <Link to="/Home">Home</Link>
            </li>
            <li>Air Freight</li>
          </ol>
        </div>
      </div>
      {/* End Breadcrumbs */}

      <section id="blog" className="blog">
        <div className="container" data-aos="fade-up">
          <div className="row g-5">
            <div className="col-lg-8" data-aos="fade-up" data-aos-delay={200}>
              <article className="blog-details">
                <div className="post-img">
                  <img style={{width:"100%"}} src="assets/img/air.png" alt="" className="img-fluid" />
                </div>
                <h2 className="title">Air Freight</h2>
                <div className="meta-top"></div>
                {/* End meta top */}
                <div className="content">
                  <p>
                    At {companyname}, we understand the importance of speed and
                    reliability when it comes to air freight forwarding. Our air
                    freight services are meticulously designed to cater to quick
                    turnarounds and just-in-time supply chains, making them the
                    perfect choice for urgent shipments, perishable goods, and
                    valuable items.
                    <br />
                    <br />
                    Working in collaboration with our extensive agency network,
                    we are proud to offer cost-competitive air freight solutions
                    on a global scale. Whether it's consolidated freight or
                    plane chartering, we have the expertise and resources to
                    handle shipments of any size or shape, navigate cargo
                    restrictions, and meet tight deadlines, regardless of the
                    destination.
                    <br />
                    <br />
                    Our comprehensive air freight services include a range of
                    options to meet diverse customer needs. For time-critical
                    shipments, we provide Full Load Charter flights and Next
                    Flight Out (NFO) services, ensuring that your goods are
                    transported with utmost urgency and priority.
                    <br />
                    <br />
                    For temperature-sensitive cargo such as pharmaceuticals and
                    perishable items, we offer Temperature-Controlled
                    Consignments to maintain the integrity of your goods
                    throughout their journey.
                    <br />
                    <br />
                    Additionally, our Consolidation services enable you to
                    optimize costs by combining multiple shipments into a single
                    shipment, while Hand Carry services ensure that valuable or
                    sensitive items are personally accompanied by our
                    experienced personnel for added security.
                    <br />
                    <br />
                    With our Door-to-Door service, we handle the entire
                    logistics process, from pick-up at the origin to final
                    delivery at the destination, taking the stress out of
                    shipping and ensuring a seamless experience for our
                    customers.
                    <br />
                    <br />
                    As part of our commitment to providing a comprehensive
                    solution, we take care of Customs Clearance, ensuring that
                    all necessary formalities are completed efficiently and
                    accurately to avoid delays and complications.
                    <br />
                    <br />
                    At {companyname}, we take pride in our ability to deliver
                    swift and reliable air freight services, allowing businesses
                    to meet their time-sensitive demands and maintain efficient
                    supply chains. No matter the complexities or challenges, our
                    dedicated team of experts is ready to assist and provide the
                    highest level of service to ensure your cargo reaches its
                    destination swiftly and securely.
                  </p>
                </div>
                {/* End post content */}
              </article>
              {/* End blog post */}
            </div>
            <div className="col-lg-4" data-aos="fade-up" data-aos-delay={400}>
              <div className="sidebar ps-lg-4">
                <div className="sidebar-item recent-posts">
                  <h3 className="sidebar-title">Our Services</h3>
                  <div className="mt-3">
                    <div className="post-item mt-3">
                      <img
                        src="assets/img/sea.png"
                        alt=""
                        className="flex-shrink-0"
                      />
                      <div>
                        <h3>
                          <Link to="/Sea">Sea Freight</Link>
                        </h3>
                      </div>
                    </div>
                    {/* End recent post item*/}
                    <div className="post-item mt-3">
                      <img
                        src="assets/img/air.png"
                        alt=""
                        className="flex-shrink-0"
                      />
                      <div>
                        <h3>
                          <Link to="/Air">Air Freight</Link>
                        </h3>
                      </div>
                    </div>
                    {/* End recent post item*/}
                    <div className="post-item mt-3">
                      <img
                        src="assets/img/road.webp"
                        alt=""
                        className="flex-shrink-0"
                      />
                      <div>
                        <h3>
                          <Link to="/Road">Road Freight</Link>
                        </h3>
                      </div>
                    </div>
                    {/* End recent post item*/}
                    <div className="post-item mt-3">
                      <img
                        src="assets/img/warehousing.webp"
                        alt=""
                        className="flex-shrink-0"
                      />
                      <div>
                        <h3>
                          <Link to="/Warehousing">Warehousing</Link>
                        </h3>
                      </div>
                    </div>
                    {/* End recent post item*/}
                    <div className="post-item mt-3">
                      <img
                        src="assets/img/consolidation.webp"
                        alt=""
                        className="flex-shrink-0"
                      />
                      <div>
                        <h3>
                          <Link to="/Consolidation">Freight Consolidation</Link>
                        </h3>
                      </div>
                    </div>
                    {/* End recent post item*/}
                    <div className="post-item mt-3">
                      <img
                        src="assets/img/dangerous.jpg"
                        alt=""
                        className="flex-shrink-0"
                      />
                      <div>
                        <h3>
                          <Link to="/Dangerous">Dangerous Cargo</Link>
                        </h3>
                      </div>
                    </div>
                    {/* End recent post item*/}
                  </div>
                </div>
                {/* End sidebar recent posts*/}
              </div>
              {/* End Blog Sidebar */}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
