import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Air() {
  return (
    <>
      <Header />
      <div
        className="breadcrumbs d-flex align-items-center"
        style={{ backgroundImage: 'url("assets/img/header.jpg")' }}
      >
        <div className="container position-relative d-flex flex-column align-items-center">
          <h2>Sea Freight</h2>
          <ol>
            <li>
              <Link to="/Home">Home</Link>
            </li>
            <li>Sea Freight</li>
          </ol>
        </div>
      </div>
      {/* End Breadcrumbs */}

      <section id="blog" className="blog">
        <div className="container" data-aos="fade-up">
          <div className="row g-5">
            <div className="col-lg-8" data-aos="fade-up" data-aos-delay={200}>
              <article className="blog-details">
                <div className="post-img">
                  <img style={{width:"100%"}} src="assets/img/sea.png" alt="" className="img-fluid" />
                </div>
                <h2 className="title">Sea Freight</h2>
                <div className="meta-top"></div>
                {/* End meta top */}
                <div className="content">
                  <p>
                    At {companyname}, we recognize that sea freight is one of
                    the most cost-effective and efficient methods to transport
                    goods across continents. We offer our customers a
                    comprehensive range of sea freight forwarding services.
                    <br />
                    <br />
                    Our sea freight services have been carefully designed to
                    provide innovative and competitively priced solutions,
                    ensuring that deliveries are made on time, every time.
                    Whether it's port-to-port solutions or onwards freight
                    forwarding by road, we have the expertise and resources to
                    handle a wide variety of shipping needs.
                    <br />
                    <br />
                    To streamline the process and enhance customer experience,
                    we offer a single point of contact account management and
                    leverage advanced IT infrastructure. This integration allows
                    for stress-free, reliable, and efficient services, ensuring
                    that shipments reach their destinations promptly and in
                    perfect condition, no matter where they are in the world.
                    <br />
                    <br />
                    Collaborating with our extensive agency network, we are able
                    to offer cost-competitive sea freight solutions on a global
                    scale. From less than container load (LCL) consignments to
                    large project shipments, we are well-equipped to handle
                    cargo of any size, shape, or weight, and navigate any cargo
                    restrictions or complexities.
                    <br />
                    <br />
                    At {companyname}, our commitment to excellence and customer
                    satisfaction drives us to continuously improve and optimize
                    our sea freight services. We understand the importance of
                    reliable and timely shipments for businesses, and we take
                    pride in providing the highest level of service to meet
                    their transportation needs. With our sea freight solutions,
                    you can rest assured that your goods will be in safe hands
                    and delivered to their destination efficiently and
                    cost-effectively.
                  </p>
                </div>
                {/* End post content */}
              </article>
              {/* End blog post */}
            </div>
            <div className="col-lg-4" data-aos="fade-up" data-aos-delay={400}>
              <div className="sidebar ps-lg-4">
                <div className="sidebar-item recent-posts">
                  <h3 className="sidebar-title">Our Services</h3>
                  <div className="mt-3">
                    <div className="post-item mt-3">
                      <img
                        src="assets/img/sea.png"
                        alt=""
                        className="flex-shrink-0"
                      />
                      <div>
                        <h3>
                          <Link to="/Sea">Sea Freight</Link>
                        </h3>
                      </div>
                    </div>
                    {/* End recent post item*/}
                    <div className="post-item mt-3">
                      <img
                        src="assets/img/air.png"
                        alt=""
                        className="flex-shrink-0"
                      />
                      <div>
                        <h3>
                          <Link to="/Air">Air Freight</Link>
                        </h3>
                      </div>
                    </div>
                    {/* End recent post item*/}
                    <div className="post-item mt-3">
                      <img
                        src="assets/img/road.webp"
                        alt=""
                        className="flex-shrink-0"
                      />
                      <div>
                        <h3>
                          <Link to="/Road">Road Freight</Link>
                        </h3>
                      </div>
                    </div>
                    {/* End recent post item*/}
                    <div className="post-item mt-3">
                      <img
                        src="assets/img/warehousing.webp"
                        alt=""
                        className="flex-shrink-0"
                      />
                      <div>
                        <h3>
                          <Link to="/Warehousing">Warehousing</Link>
                        </h3>
                      </div>
                    </div>
                    {/* End recent post item*/}
                    <div className="post-item mt-3">
                      <img
                        src="assets/img/consolidation.webp"
                        alt=""
                        className="flex-shrink-0"
                      />
                      <div>
                        <h3>
                          <Link to="/Consolidation">Freight Consolidation</Link>
                        </h3>
                      </div>
                    </div>
                    {/* End recent post item*/}
                    <div className="post-item mt-3">
                      <img
                        src="assets/img/dangerous.jpg"
                        alt=""
                        className="flex-shrink-0"
                      />
                      <div>
                        <h3>
                          <Link to="/Dangerous">Dangerous Cargo</Link>
                        </h3>
                      </div>
                    </div>
                    {/* End recent post item*/}
                  </div>
                </div>
                {/* End sidebar recent posts*/}
              </div>
              {/* End Blog Sidebar */}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
