import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Home() {
  return (
    <>
      <Header />
      <section
        id="hero"
        className="hero w3-banner jarallax d-flex align-items-center"
      >
        <video className="img-fluid" autoPlay muted loop style={{}}>
          <source src="assets/img/video.mp4" type="video/mp4" />
        </video>
        <div className="container">
          <div className="row">
            <div className="col-xl-4">
              <h2 data-aos="fade-up">
                Efficient Shipping, Endless Possibilities.
              </h2>
              <blockquote data-aos="fade-up" data-aos-delay={100}>
                <p>
                  Efficient Shipping opens up a world of endless possibilities,
                  providing seamless connectivity and boundless growth
                  opportunities for businesses worldwide. With streamlined
                  logistics, the potential for expanding markets and reaching
                  new horizons becomes limitless.
                </p>
              </blockquote>
              <div className="d-flex" data-aos="fade-up" data-aos-delay={200}>
                <Link to="/About" className="btn-get-started">
                 Learn More
                </Link>
                <Link
                  to="/Contact"
                  className="btn-get-started"
                >
                  
                  <span>Contact Us</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Hero Section */}
      <main id="main">
        {/* ======= Why Choose Us Section ======= */}
        <section id="why-us" className="why-us">
          <div className="container" data-aos="fade-up">
            <div className="section-header">
              <h2>Who We Are</h2>
            </div>
            <div className="row g-0" data-aos="fade-up" data-aos-delay={200}>
              <div
                className="col-xl-5 img-bg"
                style={{ backgroundImage: 'url("assets/img/about.jpg")' }}
              />
              <div className="col-xl-7 slides  position-relative">
                <div className="slides-1 swiper">
                  <div className="swiper-wrapper">
                    <div className="swiper-slide">
                      <div className="item">
                        <h3 className="mb-3">We are {companyname}</h3>

                        <p>
                          {companyname} Services was established by a seasoned
                          professional with extensive experience in the
                          logistics industry. Recognizing the need for expertise
                          and knowledge in freight forwarding, our company was
                          formed to provide exceptional services to the
                          export/import community. Through our commitment to
                          excellence, Prime Logistics quickly gained a
                          reputation as one of the most reliable freight
                          forwarding agents in the industry.
                          <br />
                          <br />
                          Located in Mumbai, the commercial and financial hub of
                          India, our corporate office serves as a strategic base
                          for serving clients globally. With a track record of
                          handling a wide range of commodities in large volumes,
                          odd dimensions, oversized cargo, and various
                          industries, we have developed specialized expertise to
                          meet diverse logistical challenges.
                          <br />
                          <br />
                          Transparency is a core value at {companyname}. We
                          believe in maintaining open and honest communication
                          with our clients, ensuring clarity in every aspect of
                          our operations. By upholding high service standards
                          and implementing cost-effective structures, we have
                          been successful in building long-term relationships
                          with our loyal customers.
                          <br />
                          <br />
                          <Link
                            className="cta-btn"
                            to="/About"
                            style={{
                              background: "#009846",
                              borderRadius: "50px",
                              padding: "10px 30px",
                              margin: "10px,30px",
                              color: "black",
                            }}
                          >
                            Learn More
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-pagination" />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End Why Choose Us Section */}

        <section id="recent-posts" className="recent-posts">
          <div className="container" data-aos="fade-up">
            <div className="section-header">
              <h2>Services We Provide..</h2>
            </div>
            <div className="row gy-5">
              <div
                className="col-xl-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay={100}>
                <div className="post-box">
                  <div className="post-img">
                    <img
                      src="assets/img/sea.png"
                      className="img-fluid"
                      alt=""
                      style={{ height: "240px", width:"100%"  }}
                    />
                  </div>
                 
                  <h3 className="post-title">
                   Sea Freight
                  </h3>
                  <p>
                   content
                  </p>
                  <Link
                    to="/Sea"
                    className="readmore stretched-link"
                  >
                    <span>Read More</span>
                    <i className="bi bi-arrow-right" />
                  </Link>
                </div>
              </div>
              <div
                className="col-xl-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay={100}>
                <div className="post-box">
                  <div className="post-img">
                    <img
                      src="assets/img/air.png"
                      className="img-fluid"
                      alt=""
                      style={{ height: "240px", width:"100%"  }}
                    />
                  </div>
                 
                  <h3 className="post-title">
                   Air Freight
                  </h3>
                  <p>
                   content
                  </p>
                  <Link
                    to="/Air"
                    className="readmore stretched-link"
                  >
                    <span>Read More</span>
                    <i className="bi bi-arrow-right" />
                  </Link>
                </div>
              </div>
              <div
                className="col-xl-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay={100}>
                <div className="post-box">
                  <div className="post-img">
                    <img
                      src="assets/img/road.webp"
                      className="img-fluid"
                      alt=""
                      style={{ height: "240px", width:"100%" }}
                    />
                  </div>
                 
                  <h3 className="post-title">
                   Road Freight
                  </h3>
                  <p>
                   content
                  </p>
                  <Link
                    to="/Road"
                    className="readmore stretched-link"
                  >
                    <span>Read More</span>
                    <i className="bi bi-arrow-right" />
                  </Link>
                </div>
              </div>
              <div
                className="col-xl-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay={100}>
                <div className="post-box">
                  <div className="post-img">
                    <img
                      src="assets/img/warehousing.webp"
                      className="img-fluid"
                      alt=""
                      style={{ height: "240px", width:"100%"  }}
                    />
                  </div>
                 
                  <h3 className="post-title">
                   Warehousing
                  </h3>
                  <p>
                   content
                  </p>
                  <Link
                    to="/Warehousing"
                    className="readmore stretched-link"
                  >
                    <span>Read More</span>
                    <i className="bi bi-arrow-right" />
                  </Link>
                </div>
              </div>
              <div
                className="col-xl-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay={100}>
                <div className="post-box">
                  <div className="post-img">
                    <img
                      src="assets/img/consolidation.webp"
                      className="img-fluid"
                      alt=""
                      style={{ height: "240px", width:"100%"  }}
                    />
                  </div>
                 
                  <h3 className="post-title">
                   Freight Consolidation
                  </h3>
                  <p>
                   content
                  </p>
                  <Link
                    to="/Consolidation"
                    className="readmore stretched-link"
                  >
                    <span>Read More</span>
                    <i className="bi bi-arrow-right" />
                  </Link>
                </div>
              </div>
              <div
                className="col-xl-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay={100}>
                <div className="post-box">
                  <div className="post-img">
                    <img
                      src="assets/img/dangerous.jpg"
                      className="img-fluid"
                      alt=""
                      style={{ height: "240px", width:"100%" }}
                    />
                  </div>
                 
                  <h3 className="post-title">
                   Dangerous Cargo
                  </h3>
                  <p>
                   content
                  </p>
                  <Link
                    to="/Dangerous"
                    className="readmore stretched-link"
                  >
                    <span>Read More</span>
                    <i className="bi bi-arrow-right" />
                  </Link>
                </div>
              </div>
              
            </div>
            
          </div>
        </section>
        {/* ======= Call To Action Section ======= */}
        <section id="call-to-action" className="call-to-action">
          <div className="container" data-aos="fade-up">
            <div className="row justify-content-center">
              <div className="col-lg-6 text-center">
                <h3 className="text-uppercase">CHOOSE {companyname} BECAUSE</h3>
                <p>
                At {companyname}, we offer an efficient, reliable, friendly, and professional service. Over the years, we have built a loyal client base, and both our long-standing and new customers benefit from our flexible and innovative approach tailored to meet their specific needs.<br/><br/>

We take pride in being your chosen Freight Forwarder and prioritize your trust in us and your freight. Our experienced staff is knowledgeable and capable of assisting you with various ancillary services, including guidance on Customs Procedures, Customs Classification, and Documentary Requirements.<br/><br/>

Communication is key, and our Operations and Sales teams are always available to talk with and meet our clients. We value building long-term relationships with our customers and strive to be a reliable partner in your supply chain.<br/><br/>

Choose {companyname} for freight management that takes its responsibilities seriously and is committed to serving you with dedication and care.
                </p>
                <Link className="cta-btn" to="/Getquote">
                 Request a Quote
                </Link>
              </div>
            </div>
          </div>
        </section>
        {/* End Call To Action Section */}
        {/* ======= Features Section ======= */}
       
      </main>
    

      <Footer />
    </>
  );
}
