import { Outlet, Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Header = () => {
  const [isMobileNavActive, setMobileNavActive] = useState(false);

  const handleMobileNavToggle = () => {
    setMobileNavActive(!isMobileNavActive);
  };
  useEffect(() => {
    const navDropdowns = document.querySelectorAll(".navbar .dropdown > a");

    const handleDropdownClick = (event) => {
      if (document.querySelector(".mobile-nav-active")) {
        event.preventDefault();
        const clickedDropdown = event.target;
        clickedDropdown.classList.toggle("active");
        clickedDropdown.nextElementSibling.classList.toggle("dropdown-active");

        const dropDownIndicator = clickedDropdown.querySelector(
          ".dropdown-indicator"
        );
        dropDownIndicator.classList.toggle("bi-chevron-up");
        dropDownIndicator.classList.toggle("bi-chevron-down");
      }
    };

    navDropdowns.forEach((el) => {
      el.addEventListener("click", handleDropdownClick);
    });

    return () => {
      // Cleanup: Remove event listeners when the component is unmounted
      navDropdowns.forEach((el) => {
        el.removeEventListener("click", handleDropdownClick);
      });
    };
  }, []);
  useEffect(() => {
    const handleDropdownClick = (event) => {
      if (document.querySelector(".mobile-nav-active")) {
        event.preventDefault();
        const clickedDropdown = event.target;
        clickedDropdown.classList.toggle("active");
        clickedDropdown.nextElementSibling.classList.toggle("dropdown-active");

        const dropDownIndicator = clickedDropdown.querySelector(
          ".dropdown-indicator"
        );
        dropDownIndicator.classList.toggle("bi-chevron-up");
        dropDownIndicator.classList.toggle("bi-chevron-down");
      }
    };

    const navDropdowns = document.querySelectorAll(".navbar .dropdown > a");
    navDropdowns.forEach((el) => {
      el.addEventListener("click", handleDropdownClick);
    });

    return () => {
      // Cleanup: Remove event listeners when the component is unmounted
      navDropdowns.forEach((el) => {
        el.removeEventListener("click", handleDropdownClick);
      });
    };
  }, []);

  return (
    <>
      <header
        id="header"
        className="header d-flex align-items-center fixed-top"
        style={{ background: "white" }}
      >
         <nav
            className={` ${isMobileNavActive ? "mobile-nav-active" : ""}`}
            style={{ width: "100%" }}
          >
        <div className="container-fluid container-xl d-flex align-items-center justify-content-between">
          <Link to="/Home" className="logo d-flex align-items-center">
            {/* Uncomment the line below if you also wish to use an image logo */}
            {/* <img src="assets/img/logo.png" alt=""> */}
            <img src="assets/img/logo.png" style={{ height: "150px" }}></img>
          </Link>

          <i
            className={`mobile-nav-toggle bi bi-list ${
              isMobileNavActive ? "mobile-nav-hide" : "mobile-nav-show"
            }`}
            onClick={handleMobileNavToggle}
          />
         
            <nav id="navbar" className="navbar">
              <ul style={{ paddingRight: " 40px" }}>
                <li>
                  <Link to="/Home" className="">
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/About">About</Link>
                </li>

                <li className="dropdown">
                  <Link to="#">
                    <span>Services</span>{" "}
                    <i className="bi bi-chevron-down dropdown-indicator" />
                  </Link>
                  <ul>
                    <li>
                      <Link to="/Sea">Sea Freight</Link>
                    </li>
                    <li>
                      <Link to="/Air">Air Freight</Link>
                    </li>
                    <li>
                      <Link to="/Road">Road Freight</Link>
                    </li>
                    <li>
                      <Link to="/Warehousing">Warehousing</Link>
                    </li>
                    <li>
                      <Link to="/Consolidation">Freight Consolidation</Link>
                    </li>
                    <li>
                      <Link to="/Dangerous">Dangerous Cargo</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/Getquote">Get Quote</Link>
                </li>
              </ul>
              <Link
                className="cta-btn"
                to="/Contact"
                style={{
                  background: "#009846",
                  borderRadius: "50px",
                  padding: "10px 30px",
                  margin: "10px,30px",
                }}
              >
                Contact Us
              </Link>
            </nav>
          
        </div>
        </nav>
      </header>

      <Outlet />
    </>
  );
};

export default Header;
