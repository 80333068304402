import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Air() {
  return (
    <>
      <Header />
      <div
        className="breadcrumbs d-flex align-items-center"
        style={{ backgroundImage: 'url("assets/img/header.jpg")' }}
      >
        <div className="container position-relative d-flex flex-column align-items-center">
          <h2>Freight Consolidation</h2>
          <ol>
            <li>
              <Link to="/Home">Home</Link>
            </li>
            <li>Freight Consolidation</li>
          </ol>
        </div>
      </div>
      {/* End Breadcrumbs */}

      <section id="blog" className="blog">
        <div className="container" data-aos="fade-up">
          <div className="row g-5">
            <div className="col-lg-8" data-aos="fade-up" data-aos-delay={200}>
              <article className="blog-details">
                <div className="post-img">
                  <img style={{width:"100%"}}
                    src="assets/img/consolidation.webp"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <h2 className="title">Freight Consolidation</h2>
                <div className="meta-top"></div>
                {/* End meta top */}
                <div className="content">
                  <p>
                    Freight consolidation, also known as Less Than Container
                    Load (LCL) shipping, offers a valuable solution for
                    businesses seeking to optimize their logistics operations
                    and reduce shipping costs. It involves combining multiple
                    individual shipments with the same destination into a single
                    consignment, which is then transporte
                    <br />
                    <br />d together in a shared container. This method allows
                    even small senders to take advantage of bulk transportation
                    rates, resulting in significant time and cost savings. One
                    of the primary advantages of freight consolidation is the
                    cost-effectiveness it offers to companies. By sharing
                    container space with other shipments, the overall cost is
                    divided among multiple clients, allowing each to pay only
                    for their share of the container. This helps businesses of
                    all sizes benefit from more affordable transportation rates
                    compared to exclusive container shipping.
                    <br />
                    <br />
                    Aside from cost savings, freight consolidation improves
                    operational efficiency. Rather than shipping smaller
                    individual consignments separately, combining them into a
                    single shipment streamlines the process and reduces
                    administrative work. This results in smoother operations and
                    more efficient use of resources.
                    <br />
                    <br />
                    Furthermore, freight consolidation enhances flexibility in
                    logistics planning. Companies can enjoy the freedom to ship
                    smaller volumes of goods without the need to fill an entire
                    container, which can be especially beneficial for businesses
                    with varying shipment volumes or irregular demand patterns.
                    This flexibility allows for more agile responses to market
                    changes and customer needs.
                    <br />
                    <br />
                    Another key benefit of utilizing freight consolidation is
                    the optimization of supply chains. With the ability to
                    swiftly and affordably transport smaller volumes of goods,
                    businesses can provide faster and more reliable service to
                    customers and partners. This boosts customer satisfaction
                    and fosters stronger business relationships.
                    <br />
                    <br />
                    At {companyname}, our freight consolidation services are
                    designed to cater to diverse customer requirements. We take
                    pride in offering cost-effective, efficient, and reliable
                    transportation solutions that support businesses in
                    achieving their logistics goals. By choosing freight
                    consolidation, our clients can streamline their shipping
                    processes, reduce costs, and maintain a competitive edge in
                    the market, ultimately contributing to their overall success
                    and growth.
                  </p>
                </div>
                {/* End post content */}
              </article>
              {/* End blog post */}
            </div>
            <div className="col-lg-4" data-aos="fade-up" data-aos-delay={400}>
              <div className="sidebar ps-lg-4">
                <div className="sidebar-item recent-posts">
                  <h3 className="sidebar-title">Our Services</h3>
                  <div className="mt-3">
                    <div className="post-item mt-3">
                      <img
                        src="assets/img/sea.png"
                        alt=""
                        className="flex-shrink-0"
                      />
                      <div>
                        <h3>
                          <Link to="/Sea">Sea Freight</Link>
                        </h3>
                      </div>
                    </div>
                    {/* End recent post item*/}
                    <div className="post-item mt-3">
                      <img
                        src="assets/img/air.png"
                        alt=""
                        className="flex-shrink-0"
                      />
                      <div>
                        <h3>
                          <Link to="/Air">Air Freight</Link>
                        </h3>
                      </div>
                    </div>
                    {/* End recent post item*/}
                    <div className="post-item mt-3">
                      <img
                        src="assets/img/road.webp"
                        alt=""
                        className="flex-shrink-0"
                      />
                      <div>
                        <h3>
                          <Link to="/Road">Road Freight</Link>
                        </h3>
                      </div>
                    </div>
                    {/* End recent post item*/}
                    <div className="post-item mt-3">
                      <img
                        src="assets/img/warehousing.webp"
                        alt=""
                        className="flex-shrink-0"
                      />
                      <div>
                        <h3>
                          <Link to="/Warehousing">Warehousing</Link>
                        </h3>
                      </div>
                    </div>
                    {/* End recent post item*/}
                    <div className="post-item mt-3">
                      <img
                        src="assets/img/consolidation.webp"
                        alt=""
                        className="flex-shrink-0"
                      />
                      <div>
                        <h3>
                          <Link to="/Consolidation">Freight Consolidation</Link>
                        </h3>
                      </div>
                    </div>
                    {/* End recent post item*/}
                    <div className="post-item mt-3">
                      <img
                        src="assets/img/dangerous.jpg"
                        alt=""
                        className="flex-shrink-0"
                      />
                      <div>
                        <h3>
                          <Link to="/Dangerous">Dangerous Cargo</Link>
                        </h3>
                      </div>
                    </div>
                    {/* End recent post item*/}
                  </div>
                </div>
                {/* End sidebar recent posts*/}
              </div>
              {/* End Blog Sidebar */}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
