import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Services() {
  return (
    <>
      <Header />

      <div
        className="breadcrumbs d-flex align-items-center"
        style={{ backgroundImage: 'url("assets/img/header.jpg")' }}
      >
        <div className="container position-relative d-flex flex-column align-items-center">
          <h2>Services</h2>
          <ol>
            <li>
              <Link to="/Home">Home</Link>
            </li>
            <li>Services</li>
          </ol>
        </div>
      </div>
      {/* End Breadcrumbs */}
      <section id="recent-posts" className="recent-posts">
          <div className="container" data-aos="fade-up">
            <div className="section-header">
              <h2>Services We Provide..</h2>
            </div>
            <div className="row gy-5">
              <div
                className="col-xl-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay={100}>
                <div className="post-box">
                  <div className="post-img">
                    <img
                      src="assets/img/sea.png"
                      className="img-fluid"
                      alt=""
                      style={{ height: "240px", width:"100%"  }}
                    />
                  </div>
                 
                  <h3 className="post-title">
                   Sea Freight
                  </h3>
                  <p>
                   content
                  </p>
                  <Link
                    to="/Sea"
                    className="readmore stretched-link"
                  >
                    <span>Read More</span>
                    <i className="bi bi-arrow-right" />
                  </Link>
                </div>
              </div>
              <div
                className="col-xl-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay={100}>
                <div className="post-box">
                  <div className="post-img">
                    <img
                      src="assets/img/air.png"
                      className="img-fluid"
                      alt=""
                      style={{ height: "240px", width:"100%"  }}
                    />
                  </div>
                 
                  <h3 className="post-title">
                   Air Freight
                  </h3>
                  <p>
                   content
                  </p>
                  <Link
                    to="/Air"
                    className="readmore stretched-link"
                  >
                    <span>Read More</span>
                    <i className="bi bi-arrow-right" />
                  </Link>
                </div>
              </div>
              <div
                className="col-xl-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay={100}>
                <div className="post-box">
                  <div className="post-img">
                    <img
                      src="assets/img/road.webp"
                      className="img-fluid"
                      alt=""
                      style={{ height: "240px", width:"100%" }}
                    />
                  </div>
                 
                  <h3 className="post-title">
                   Road Freight
                  </h3>
                  <p>
                   content
                  </p>
                  <Link
                    to="/Road"
                    className="readmore stretched-link"
                  >
                    <span>Read More</span>
                    <i className="bi bi-arrow-right" />
                  </Link>
                </div>
              </div>
              <div
                className="col-xl-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay={100}>
                <div className="post-box">
                  <div className="post-img">
                    <img
                      src="assets/img/warehousing.webp"
                      className="img-fluid"
                      alt=""
                      style={{ height: "240px", width:"100%"  }}
                    />
                  </div>
                 
                  <h3 className="post-title">
                   Warehousing
                  </h3>
                  <p>
                   content
                  </p>
                  <Link
                    to="/Warehousing"
                    className="readmore stretched-link"
                  >
                    <span>Read More</span>
                    <i className="bi bi-arrow-right" />
                  </Link>
                </div>
              </div>
              <div
                className="col-xl-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay={100}>
                <div className="post-box">
                  <div className="post-img">
                    <img
                      src="assets/img/consolidation.webp"
                      className="img-fluid"
                      alt=""
                      style={{ height: "240px", width:"100%"  }}
                    />
                  </div>
                 
                  <h3 className="post-title">
                   Freight Consolidation
                  </h3>
                  <p>
                   content
                  </p>
                  <Link
                    to="/Consolidation"
                    className="readmore stretched-link"
                  >
                    <span>Read More</span>
                    <i className="bi bi-arrow-right" />
                  </Link>
                </div>
              </div>
              <div
                className="col-xl-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay={100}>
                <div className="post-box">
                  <div className="post-img">
                    <img
                      src="assets/img/dangerous.jpg"
                      className="img-fluid"
                      alt=""
                      style={{ height: "240px", width:"100%" }}
                    />
                  </div>
                 
                  <h3 className="post-title">
                   Dangerous Cargo
                  </h3>
                  <p>
                   content
                  </p>
                  <Link
                    to="/Dangerous"
                    className="readmore stretched-link"
                  >
                    <span>Read More</span>
                    <i className="bi bi-arrow-right" />
                  </Link>
                </div>
              </div>
              
            </div>
            
          </div>
        </section>

      <Footer />
    </>
  );
}
