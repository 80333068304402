import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Air() {
  return (
    <>
      <Header />
      <div
        className="breadcrumbs d-flex align-items-center"
        style={{ backgroundImage: 'url("assets/img/header.jpg")' }}
      >
        <div className="container position-relative d-flex flex-column align-items-center">
          <h2>Warehousing</h2>
          <ol>
            <li>
              <Link to="/Home">Home</Link>
            </li>
            <li>Warehousing</li>
          </ol>
        </div>
      </div>
      {/* End Breadcrumbs */}

      <section id="blog" className="blog">
        <div className="container" data-aos="fade-up">
          <div className="row g-5">
            <div className="col-lg-8" data-aos="fade-up" data-aos-delay={200}>
              <article className="blog-details">
                <div className="post-img">
                  <img style={{width:"100%"}}
                    src="assets/img/warehousing.webp"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <h2 className="title">Warehousing</h2>
                <div className="meta-top"></div>
                {/* End meta top */}
                <div className="content">
                  <p>
                    At {companyname}, our network of warehouses services revolve
                    around the core principle of precision and timeliness. We
                    understand the critical importance of ensuring that each
                    consignment and inventory item is strategically positioned
                    at the right place and delivered at the right time. To
                    achieve this level of efficiency, we have established robust
                    processes, advanced systems, and meticulous procedures to
                    manage, administer, and transport stock seamlessly.
                    <br />
                    <br />
                    As part of our comprehensive warehousing solutions, we offer
                    full inventory management services across all our global
                    warehousing hubs. This means that our clients can rely on us
                    to take care of their entire inventory, from receiving goods
                    to organizing, storing, and dispatching them as per their
                    specific requirements. Our experienced warehouse
                    professionals handle the entire inventory lifecycle with
                    utmost precision and attention to detail.
                    <br />
                    <br />
                    Our commitment to excellence is further underlined by the
                    fact that our warehousing services are fully accredited by
                    the respective local authorities. These accreditations
                    demonstrate our dedication to maintaining the highest
                    standards of professionalism and adherence to industry
                    regulations. Clients can trust that their valuable goods and
                    inventory are in safe and capable hands when partnering with
                    us.
                    <br />
                    <br />
                    At each of our warehousing facilities, we implement advanced
                    technologies and cutting-edge systems to ensure accurate
                    tracking and real-time monitoring of inventory movements.
                    This level of visibility empowers our clients to have full
                    control and visibility over their stock, enabling them to
                    make informed decisions and optimize their supply chain
                    operations.
                    <br />
                    <br />
                    Our warehousing services are not only designed to store
                    goods efficiently but also to facilitate seamless order
                    fulfillment. Through streamlined picking, packing, and
                    shipping processes, we ensure that our clients' orders are
                    prepared and dispatched promptly, meeting their customers'
                    demands and expectations.
                    <br />
                    <br />
                    At {companyname}, we take great pride in our warehouse
                    services' quality, efficiency, and reliability. Our
                    dedicated team works tirelessly to uphold our commitment to
                    excellence, providing our clients with a competitive
                    advantage in the market. Whether it's a local or global
                    warehouse requirement, our comprehensive warehousing
                    solutions cater to diverse industries and ensure the smooth
                    flow of goods from origin to destination, ultimately driving
                    our clients' success and satisfaction.
                  </p>
                </div>
                {/* End post content */}
              </article>
              {/* End blog post */}
            </div>
            <div className="col-lg-4" data-aos="fade-up" data-aos-delay={400}>
              <div className="sidebar ps-lg-4">
                <div className="sidebar-item recent-posts">
                  <h3 className="sidebar-title">Our Services</h3>
                  <div className="mt-3">
                    <div className="post-item mt-3">
                      <img
                        src="assets/img/sea.png"
                        alt=""
                        className="flex-shrink-0"
                      />
                      <div>
                        <h3>
                          <Link to="/Sea">Sea Freight</Link>
                        </h3>
                      </div>
                    </div>
                    {/* End recent post item*/}
                    <div className="post-item mt-3">
                      <img
                        src="assets/img/air.png"
                        alt=""
                        className="flex-shrink-0"
                      />
                      <div>
                        <h3>
                          <Link to="/Air">Air Freight</Link>
                        </h3>
                      </div>
                    </div>
                    {/* End recent post item*/}
                    <div className="post-item mt-3">
                      <img
                        src="assets/img/road.webp"
                        alt=""
                        className="flex-shrink-0"
                      />
                      <div>
                        <h3>
                          <Link to="/Road">Road Freight</Link>
                        </h3>
                      </div>
                    </div>
                    {/* End recent post item*/}
                    <div className="post-item mt-3">
                      <img
                        src="assets/img/warehousing.webp"
                        alt=""
                        className="flex-shrink-0"
                      />
                      <div>
                        <h3>
                          <Link to="/Warehousing">Warehousing</Link>
                        </h3>
                      </div>
                    </div>
                    {/* End recent post item*/}
                    <div className="post-item mt-3">
                      <img
                        src="assets/img/consolidation.webp"
                        alt=""
                        className="flex-shrink-0"
                      />
                      <div>
                        <h3>
                          <Link to="/Consolidation">Freight Consolidation</Link>
                        </h3>
                      </div>
                    </div>
                    {/* End recent post item*/}
                    <div className="post-item mt-3">
                      <img
                        src="assets/img/dangerous.jpg"
                        alt=""
                        className="flex-shrink-0"
                      />
                      <div>
                        <h3>
                          <Link to="/Dangerous">Dangerous Cargo</Link>
                        </h3>
                      </div>
                    </div>
                    {/* End recent post item*/}
                  </div>
                </div>
                {/* End sidebar recent posts*/}
              </div>
              {/* End Blog Sidebar */}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
